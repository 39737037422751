import { IntlShape } from 'react-intl';
import messages from '../components/messages/duplicatesTable.messages';
import { v4 as uuid } from 'uuid';
import { MainStrategyType, TNewSelectedItemsByGroup } from '../types/searchDuplicates.types';
import { DuplicatesElementInfo, DuplicatesGroup } from '@/serverapi/api';
import { MutableRefObject } from 'react';

export const successStatuses: string[] = ['SUCCESS', 'SKIP', 'SUCCESS_DELETED'];

export const processStrategy = (
    prevState: TNewSelectedItemsByGroup,
    duplicatesGroups: DuplicatesGroup[],
    strategy: string,
    isFirstSkip: MutableRefObject<boolean>,
): TNewSelectedItemsByGroup => {
    const newSelectedItemsByGroup: TNewSelectedItemsByGroup = { ...prevState };

    duplicatesGroups.forEach((group: DuplicatesGroup) => {
        const groupId: string = group.duplicatesElementInfo.length > 0 ? group.duplicatesElementInfo[0].id || uuid() : uuid();

        newSelectedItemsByGroup[groupId] = { ...prevState[groupId] };

        group.duplicatesElementInfo.forEach((element) => {
            const elementId: string = element?.id || uuid();
            newSelectedItemsByGroup[groupId][elementId] = {
                combine: prevState[groupId]?.[elementId]?.combine || false,
                main: false,
            };
        });

        let elementToSetMain: DuplicatesElementInfo | undefined;

        if (strategy === MainStrategyType.Auto) {
            elementToSetMain = group.duplicatesElementInfo.reduce((maxElement, currentElement) =>
                (currentElement.instanceCount || 0) > (maxElement.instanceCount || 0) ? currentElement : maxElement,
            );
        } else if (strategy === MainStrategyType.Oldest) {
            elementToSetMain = group.duplicatesElementInfo.reduce((oldest, current) =>
                new Date(current.createdAt || 0) < new Date(oldest.createdAt || 0) ? current : oldest,
            );
        }

        if (elementToSetMain) {
            const elementId = elementToSetMain.id || uuid();
            newSelectedItemsByGroup[groupId][elementId].main = true;
        }
    });

    if (strategy === MainStrategyType.Skip && isFirstSkip.current) {
        isFirstSkip.current = false;
        return newSelectedItemsByGroup;
    }

    if (strategy !== MainStrategyType.Skip) {
        return newSelectedItemsByGroup;
    }

    return prevState;
};

export const getOperationStatusText = (intl: IntlShape, status: string) => {
    switch (status) {
        case 'SUCCESS':
            return intl.formatMessage(messages.combined);
        case 'SUCCESS_DELETED':
            return intl.formatMessage(messages.deleted);
        case 'SKIP':
            return intl.formatMessage(messages.skipped);
        case 'LOCKED':
            return intl.formatMessage(messages.blocked);
        case 'LOCKED_BY_MODEL':
            return intl.formatMessage(messages.blockedByModel);
        case 'NOT_FOUND':
            return intl.formatMessage(messages.notFound);
        case 'ACCESS_DENIED':
            return intl.formatMessage(messages.noAccess);
        case 'DIFFERENT_TYPE':
            return intl.formatMessage(messages.typesDifference);
        default:
            return '';
    }
};

export const getConsolidationInfo = (
    intl: IntlShape,
    selectedItemsByGroup: TNewSelectedItemsByGroup,
    groupId: string,
    combiningInProcess: boolean,
) => {
    const combineItemsCount: number = Object.values(selectedItemsByGroup[groupId] || {}).filter(
        (item) => item.combine,
    ).length;
    const hasMainItem: boolean = Object.values(selectedItemsByGroup[groupId] || {}).some((item) => item.main);

    const canConsolidate: boolean = combineItemsCount >= 2 && hasMainItem && !combiningInProcess;

    let consolidateTip: string = '';
    if (combineItemsCount < 2) {
        consolidateTip = intl.formatMessage(messages.minTwoTip);
    } else if (!hasMainItem) {
        consolidateTip = intl.formatMessage(messages.mainTip);
    }

    return { canConsolidate, consolidateTip };
};

export const sortElementsByCreatedAt = (elements: DuplicatesElementInfo[]): DuplicatesElementInfo[] => {
    return elements.slice().sort((a, b) => {
        const dateA: Date = new Date(a.createdAt || 0);
        const dateB: Date = new Date(b.createdAt || 0);
        return dateA.getTime() - dateB.getTime();
    });
};
